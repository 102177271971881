const MultilineDataSetFunction = (title, array_of_values, color, lineTension, fillBolean=0) => {
    return {
        label: title,
        data: array_of_values,
        borderColor: color,
        backgroundColor: color,
        fill: fillBolean,
        lineTension: lineTension
      }
  }


const RoundedBarChartDataSetFunction = (title, array_of_values, backgroundColor, borderColor, borderWidth = 0 ) => {
    return {
        label: title,
        data: array_of_values,
        backgroundColor: backgroundColor,
        borderColor: borderColor
      }
  }

export {MultilineDataSetFunction, RoundedBarChartDataSetFunction}