
export default function Table11() {
    return (
        <table className="table-fixed mb-2">
            <thead className=" border-black border-2">
                <tr className="bg-[#21B5A3]">
                    <th colSpan={27} className="px-2 text-center border-black border-l-2 border-r-2 border-b-2 border-b-2">Percentage of households by ownership of selected household and personal assets, and percent distribution by ownership of dwelling, by area of residence and region, Nigeria MICS, 2021</th>
                </tr>
                <tr className=" bg-[#21B5A3]">
                    <th></th>
                    <th className="px-2 py-10 border-black border-b-2 border-l-2 border-r-2 text-center" rowSpan={2} colSpan={8}>Percentage of households that own a </th>
                    <th className="border-black border-b-2 border-l-2 border-r-2 px-2 py-10 text-center" rowSpan={2} colSpan={2}>Percentage of house- holds that  own</th>
                    <th className="border-black border-b-2 border-l-2 border-r-2 px-2 py-10 text-center" rowSpan={2} colSpan={10}>Percentage of households where at least one member owns or has a</th>
                    <th className="border-black border-b-2 border-l-2 border-r-2 px-2 py-10 text-center" rowSpan={2} colSpan={5}>Ownership of dwelling</th>
                    <th className="border-black  border-l-2 border-r-2 px-2 py-10 text-center" rowSpan={3}>Number of house</th>
                </tr>
                
            </thead>
            <tbody className="border-black border-2">  
            <tr className="border-black border-b-2">
                    <td></td>
                    <td className="px-2 border-black border-l-2 border-r-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Television</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Refrigerator</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Air conditioner</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Fan</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Water heater</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>VCR, VCD, DVD</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Blender/MIxer or food Processor</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Electric iron</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Agricultural land</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Farm animals/ Livestock</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Wristwatch</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Bicycle</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Motorcycle or scooter</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Animal-drawn cart</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Car, truck, or van</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Boat with a motor</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Tricycle (Keke- NAPEP)</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Computer or tablet</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Mobile telephone</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Bank account</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Owned by a household member</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Not owned</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Rented</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Other</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Missing/DK</td>
                </tr>
                <tr className="border-black border-b-2">
                </tr>
                <tr className="border-black border-b-2">
                    <td className="px-4 border-black border-l-2 border-r-2 font-bold text-center" >State</td>
                    <td className="border-black border-l-2 border-r-2" colSpan={26}></td>
                </tr>
                <tr className="border-black border-b-2">
                    <td className="px-4 border-black border-l-2 border-r-2 text-center" >Katsina</td>
                    <td className="px-2 border-black border-l-2 border-r-2" >11.9</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >8.1</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >1.4</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >16.3</td>
                    <td className="px-2 border-black border-l-2 border-r-2" >6.1</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >9.7</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >6.6</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >14.5</td>
                    <td className="px-2 border-black border-l-2 border-r-2" >82</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >73.8</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >37.1</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >27.2</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >28.7</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >18.2</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >5.2</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >1.7</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >1.9</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >4.6</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >72</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >27.8</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >92.6</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >7.4</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >3.7</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >3.7</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >0</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >1,389</td>
                </tr>
            </tbody>
        </table>
    )
}
