import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto'

const MyChartComponent = ({ data, options }) => {
  const chartRef = useRef(null);
  let myChart = null;

  useEffect(() => {
    if (chartRef && chartRef.current) {
      if (myChart) {
        myChart.destroy();
      }
      myChart = new Chart(chartRef.current.getContext('2d'), {
        type: 'line',
        data: data,
        options: options
      });
    }
  }, [data, options]);

  return <canvas ref={chartRef} />;
};

export default MyChartComponent;