import { useJwt } from "react-jwt";

function IsCurrentUser() {
    const token = localStorage.getItem('access_token')
    const { decodedToken, isExpired } = useJwt(token);
    if (decodedToken?.sub){
        return true
    }
}

export default IsCurrentUser;