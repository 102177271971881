import React from 'react';
import { Bar } from 'react-chartjs-2';

const StackedBarChart = ({ ArrayOfStackedBarChartDataSet, text, labels }) => {
  const data = {
    labels: labels,
    datasets: ArrayOfStackedBarChartDataSet
  };

  const options = {
    indexAxis: 'x',
    elements: {
      bar: {
        borderRadius: 20
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true
      },
      title: {
        display: true,
        text: text
      }
    },
    scales: {
      x: {
        stacked: true
      },
      y: {
        display: false,
        stacked: true
      }
    }
  };

  return (
    <div className='w-full h-96'>
      <Bar data={data} options={options} />
    </div>
  );
};

export default StackedBarChart;
