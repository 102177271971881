
export default function Table7a() {
    return (
        <table className="table-fixed mb-2 mx-auto">
            <thead className=" border-black border-2">
                <tr className=" bg-[#21B5A3]">
                    <th></th>
                    <th className="px-2 py-10 border-black border-b-2 border-l-2 border-r-2 text-center"  colSpan={5}> Households </th>
                    <th className="border-black border-b-2 border-l-2 border-r-2 px-2 py-10 text-center"  colSpan={4}> Women age 15-49 years </th>
                    <th className="border-black border-b-2 border-l-2 border-r-2 px-2 py-10 text-center"  colSpan={5}>Men age 15-49 years</th>
                    <th className="border-black border-b-2 border-l-2 border-r-2 px-2 py-10 text-center"  colSpan={4}>Children under 5</th>
                    <th className="border-black border-b-2 border-l-2 border-r-2 px-2 py-10 text-center"  colSpan={5}>Children age 5 -17 years</th>
                </tr>
                
            </thead>
            <tbody className="border-black border-2">  
            <tr className="border-black border-b-2">
                    <td></td>
                    <td className="px-2 border-black border-l-2 border-r-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Sampled</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Occupied</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Interviewed</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Household completion rate</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Household response rate</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Eligible</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Interviewed</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Women's response rate</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Women's overall response rate</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Number of men in interviewed households</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Eligible</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Interviewed</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Men's response rate</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Men's overall response rate</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Eligible</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Mothers/caretakers interviewed</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Under-5's response rate</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Under-5's overall response rate</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Number of children in interviewed households</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Selected</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Mothers/caretakers interviewed</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Children age 5-17's response rate</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Children age 5-17's overall response rate</td>
                </tr>
                <tr className="border-black border-b-2">
                </tr>
                <tr className="border-black border-b-2">
                    <td className="px-4 border-black border-l-2 border-r-2 font-bold text-center" >State</td>
                    <td className="border-black border-l-2 border-r-2" colSpan={26}></td>
                </tr>
                <tr className="border-black border-b-2">
                    <td className="px-4 border-black border-l-2 border-r-2 text-center" >Katsina</td>
                    <td className="px-2 border-black border-l-2 border-r-2" >940</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >927</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >927</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >98.6</td>
                    <td className="px-2 border-black border-l-2 border-r-2" >100</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >1,316</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >1,303</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >99</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >99</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >1,193</td>
                    <td className="px-2 border-black border-l-2 border-r-2" >584</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >558</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >95.5</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >95.5</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >1,198</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >1,197</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >99.9</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >99.9</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >2,596</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >720</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >719</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >99.9</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >99.9</td>
                </tr>
            </tbody>
        </table>
    )
}
