import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



// export default function Toast({type, message, position, autoClose}) {
//     const notify = () => {
//         toast.success(message, {
//             position: position,
//             id: 1
//           });
//         }
//          return (
//             <>
//               {notify()}
//               <ToastContainer />
//             </>
//           );
// }


export default function Toast({type, message, position, autoClose, subGoalsUpdated}) {
    // const notify = () => toast("Wow so easy!");

    return (
      <div>
        {/* <button onClick={notify}>Notify!</button> */}
        {/* {notify()} */}
        <ToastContainer />
      </div>
    );;
}