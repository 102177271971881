import { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import logo from "../img/katsina_state_logo.png";
import katsina_state_logo2 from '../img/katsina_state_logo2.jpg';
import closeIcon from "../img/icons8-close.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useJwt } from "react-jwt";
import { ClickAwayListener } from "@mui/material";

const NavBar = ({ username, setAboutUsModal, setContactUsModal, homePage }) => {
  const [menuButtonState, setMenuButtonState] = useState(false);
  const [homeButtonClicked, setHomeButtonClicked] = useState(false);
  const [SDGsButtonClicked, setSDGsButtonClicked] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  const onViewClickHistory = useHistory();
  const routeChange = () => {
    let path = `/`;
    onViewClickHistory.push(path);
  }

  const access_token = localStorage.getItem("access_token")
  const { decodedToken, isExpired } = useJwt(access_token);

  function trimUsername(username) {
    const index = username?.indexOf("@");
    const unFormattedUsername = username?.slice(0, index)
    if (unFormattedUsername.startsWith("ministry")) {
      const splittedUsername = unFormattedUsername.split("of")
      return `${splittedUsername[0]} of ${splittedUsername[1]}`;
    }
    return unFormattedUsername;
  }

  const handleLogOut = () => {
    localStorage.setItem("access_token", null)
    setIsLoggedIn(false)
    routeChange()
  }

  const handleClickAway = () => {
    setMenuButtonState(false)
  }

  const handleHomeButtonClick = (e) => {
    setHomeButtonClicked(true)
    setSDGsButtonClicked(false)
    // console.log("e.target",e.target.text)
  }

  const handleSDGsButtonClicked = (e) => {
    setSDGsButtonClicked(true)
    setHomeButtonClicked(false)
    // console.log("e.target",e.target.text)
  }

  useEffect(() => {
    setIsLoggedIn(decodedToken?.sub ? true : false)
  }, [isLoggedIn])

  return (
    <nav class="border-gray-200 bg-white dark:bg-gray-800 dark:border-gray-700">
      <div class="flex  items-center justify-between px-10">
        <Link to="/" style={{ textDecoration: 'none' }}>
          <a className="flex flex-row w-full items-center no-underline py-1">
            <img src={katsina_state_logo2} height={90} width={90} />
            <div flex flex-row w-full>
              <h3 className="text-green-700 font-bold">KATSINA STATE</h3>
              <h1 className="text-red-500">Home of Hospitality</h1>
            </div>
          </a>
        </Link>

        <div className="flex flex-row items-center justify-center text-lg font-semibold show-hamburger-icon">
          <div className="pr-1 capitalize">{decodedToken?.sub ? `${trimUsername(username)}` : ""}</div>
          <div className="flex">
            <ClickAwayListener onClickAway={handleClickAway}>
              <button onClick={() => setMenuButtonState(!menuButtonState)} type="button" class={`${menuButtonState ? "hidden" : ""} text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600`} aria-controls="navbar-hamburger" aria-expanded="false">
                <span class="sr-only">Open main menu</span>
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
                </svg>
              </button>
            </ClickAwayListener>
            <div className={`cursor-pointer ${menuButtonState ? "" : "hidden"}`} onClick={() => setMenuButtonState(!menuButtonState)}>
              <img src={closeIcon} height="20" width="20" />
            </div>
          </div>
        </div>


        {/* Mobile nav */}
        <div class={`${!menuButtonState ? "hidden" : null} show-hamburger-icon bg-[#547B9A] w-1/2 position-absolute top-20 right-1 z-10 rounded-sm`} id="navbar-hamburger">
          <ul class="flex flex-col font-medium mt-4 rounded-lg dark:bg-gray-800 dark:border-gray-700 no-underline">
            <li className="cursor-pointer">
              <Link to="/" style={{ textDecoration: 'none' }}>
                <span class="block py-2 px-3 text-white rounded hover:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white no-underline">Home</span>
              </Link>
            </li>
            <Link to="/login" style={{ textDecoration: 'none' }}>
              <span class="block py-2 px-3 text-white rounded hover:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white no-underline">SDGs</span>
            </Link>
            {!homePage ?
              <>
                <li className="cursor-pointer">
                  <span class="block py-2 px-3 text-white rounded hover:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white no-underline" onClick={() => { window.location.replace("/#about") }}>About</span>
                </li>
                <li className="cursor-pointer">
                  <span class="block py-2 px-3 text-white rounded hover:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white no-underline" onClick={() => window.location.replace("/#contact")}>Contact us</span>
                  {/* <span class="block py-2 px-3 text-white rounded hover:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white no-underline" onClick={() => setContactUsModal(true)}>Contact us</span> */}
                </li>
              </>
              : null}
            {
              decodedToken?.sub ?
                <li className="cursor-pointer">
                  <span onClick={handleLogOut} class="block py-2 px-3 text-white rounded hover:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white no-underline">Log out</span>
                </li>
                : null
            }
          </ul>
        </div>

        <div class="w-full md:block md:w-auto show-horizontal-menu flex flex-col justify-end items-end" id="navbar-default">
          <div className="capitalize font-bold ml-1">{decodedToken?.sub ? `${trimUsername(username)}` : ""}</div>
          <ul class="font-medium flex flex-col rounded-lg md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            <Link to="/" style={{ textDecoration: 'none' }}>
              <span className={`block py-2 px-1 text-gray-900 rounded ${homeButtonClicked ? "text-blue-500 border-blue-700 border-b-2" : null} border-blue-700 hover:border-b-2 cursor-pointer md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent`} onClick={handleHomeButtonClick}>Home</span>
            </Link>
            {/* <li>
              <span class={`block py-2 px-1 text-gray-900 rounded ${SDGsButtonClicked ? "text-blue-500 border-blue-700 border-b-2" : null} border-blue-700 hover:border-b-2 cursor-pointer md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent`} onClick={handleSDGsButtonClicked}>SDGs</span>
            </li> */}
            <Link to="/login" style={{ textDecoration: 'none' }}>
              <span class={`block py-2 px-1 text-gray-900 rounded ${homeButtonClicked ? "text-blue-500 border-blue-700 border-b-2" : null} border-blue-700 hover:border-b-2 cursor-pointer md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent`}>SDGs</span>
            </Link>
            {!homePage ?
              <>
                <li>
                  <span class="block py-2 px-1 text-gray-900 rounded  border-blue-700 hover:border-b-2 cursor-pointer md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent" onClick={() => window.location.replace("/#about")}>About</span>
                </li>
                <li>
                  <span class="block py-2 px-1 text-gray-900 rounded border-blue-700 hover:border-b-2 focus:border-teal focus:outline-none focus:ring-0 cursor-pointer md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent" onClick={() => window.location.replace("/#contact")}>Contact us</span>
                  {/* <span class="block py-2 px-1 text-gray-900 rounded border-blue-700 hover:border-b-2 focus:border-teal focus:outline-none focus:ring-0 cursor-pointer md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent" onClick={() => setContactUsModal(true)}>Contact us</span> */}
                </li>
              </>
              : null}
            {
              decodedToken?.sub ?
                <li className="cursor-pointer">
                  <span onClick={handleLogOut} class="block py-2 px-1 text-gray-900 rounded border-blue-700 hover:border-b-2 cursor-pointer md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Log out</span>
                </li>
                : null
            }
          </ul>
        </div>



      </div>
    </nav>
  )
};

export default NavBar;