import { ClickAwayListener } from "@mui/material";



export default function FlashModal({ message, children, setSubGoalsUpdated, setReRenderPage }) {

    const handleClickAway = () => {
        setSubGoalsUpdated(false)
        setReRenderPage(false)
    }
    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div class="flash-card position-absolute right-4 p-4 w-full max-w-md max-h-1/2 ease-in-out transition-all duration-1000 z-50 ">
                <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button type="button" onClick={() => { setSubGoalsUpdated(false); setReRenderPage(false)}} class="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </button>
                    <div class="p-4 md:p-5 text-center">
                        <div class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200">
                            {children}
                        </div>
                        <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{message}</h3>
                    </div>
                </div>
            </div>
        </ClickAwayListener>
    )

}