import React from 'react';
import { Bar } from 'react-chartjs-2';

const FullyRoundedBarChart = ({ArrayOfRoundedBarChartDataSet, text, y_axis_display, labels}) => {
    const data = {
        // labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        labels: labels,
        datasets: ArrayOfRoundedBarChartDataSet
      };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'x',
    elements: {
      bar: {
        borderRadius: 20 
      }
    },
    plugins: {
      legend: {
        display: true,
        
        position: "bottom"
      },
      title: {
        display: true,
        text: text
      }
    },
    scales: {
      x: {
        stacked: false
      },
      y: {
        stacked: false,
        display: y_axis_display
      }
    }
  };

  return (
  <div className='w-full h-96'>
    <Bar data={data} options={options} />
  </div>
  );
};

export default FullyRoundedBarChart;
