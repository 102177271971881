
export default function Table3() {
    return (
        <table className="table-fixed mb-2">
            <thead className=" border-black border-2">
                <tr className="bg-[#21B5A3]">
                    <th colSpan={18} className="px-2 text-center border-black border-l-2 border-r-2 border-b-2 border-b-2">Percent distribution of households by selected housing characteristics, by area of residence and region, Nigeria MICS, 2021</th>
                </tr>
                <tr className="border-black bg-[#21B5A3]">
                    <th></th>
                    <th className="px-2 border-black border-b-2 border-l-2 border-r-2 text-center" colSpan={5}>Main material of roof</th>
                    <th className="border-black border-b-2 border-l-2 border-r-2 px-2 text-center" colSpan={5}>Main material of exterior walls</th>
                    <th className="border-black border-b-2 border-l-2 border-r-2 px-2 text-center" colSpan={3}>Rooms used for sleeping</th>
                    <th className="border-black border-b-2 border-l-2 border-r-2 px-2 text-center" rowSpan={2}>Number of households</th>
                    <th className="border-black border-b-2 border-l-2 border-r-2 px-2 text-center" rowSpan={2}>Mean number of persons per room used for sleeping</th>
                    <th className="border-black border-b-2 border-l-2 border-r-2 px-2 text-center" rowSpan={2}>Percentage of household members with access to electricity in the household</th>
                    <th className="border-black border-b-2 border-l-2 border-r-2 px-2 text-center" rowSpan={2}>Number of household members</th>
                </tr>
                <tr className="border-black border-b-2">
                    <td></td>
                    <td className="px-2 border-black border-l-2 border-r-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Natural roofing</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Rudimen- tary roofing</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Finished roofing</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Other</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>DK/ Missing</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Natural</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Rudimen- tary walls</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>Finished walls</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>other</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>DK/ Missing</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>1</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>2</td>
                    <td className="border-black border-l-2 border-r-2 px-2 [writing-mode:vertical-lr] text-center" rowSpan={2}>3 or more</td>
                </tr>
            </thead>
            <tbody className="border-black border-2">  
                <tr className="border-black border-b-2">
                    <td className="px-4 border-black border-l-2 border-r-2 font-bold text-center" >State</td>
                    <td className="border-black border-l-2 border-r-2" colSpan={17}></td>
                </tr>
                <tr className="border-black border-b-2">
                    <td className="px-4 border-black border-l-2 border-r-2 text-center" >Katsina</td>
                    <td className="px-2 border-black border-l-2 border-r-2" >20.7</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >5.7</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >73.4</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >0.2</td>
                    <td className="px-2 border-black border-l-2 border-r-2" >0</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >7.4</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >61.8</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >30.7</td>
                    <td className="px-2 border-black border-l-2 border-r-2" >0</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >0.1</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >27</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >38.9</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >34.2</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >1,389</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >3.25</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >34.9</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >9,630</td>
                </tr>
                <tr className="border-black border-b-2 text-center font-bold text-sm">
                    <td colSpan={18}>1 MICS indicator SR.1 - Access to electricity; SDG Indicator 7.1.1</td>
                </tr>
            </tbody>
        </table>
    )
}
