import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

const AuthenticatedComponents = (props) => {
    const [authenticated, setAuthenticated] = useState(false);
    const [error, setError] = useState(null);

    console.log("From AuthenticatedComponents")

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        console.log("token", token==null)
        if (token === null || !token) {
            setError('No token found');
            return;
        }
        try {
            setAuthenticated(true);
        } catch (err) {
            setError(err.message);
        }
    }, [error]);
    if (error) {
        console.log("from error page")
        return <Redirect to='/' />
    }
    if (authenticated) {
        console.log("from success page")
        return props.children;
    }
}
export default AuthenticatedComponents;