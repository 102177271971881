import React, { useEffect, useState } from "react";
import AxiosInstance from "./authorization/AxiosInstance";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import reload from '../img/reload.gif';



export default function EditModal({ subGoals, setEditModalState, setReRenderPage, setSubGoalsUpdated, username, year }) {
    const [subGoalSelectValue, setSubGoalSelectValue] = useState(null)
    const [isTextField, setIsTextField] = useState(false)
    const [loading, setLoading] = useState(false)


    if (subGoals?.type == "LineChart") {
        subGoals = [subGoals]
    }

    if (subGoals?.type == "DoughnutChart" || subGoals?.type == "HalfDoughnutChart" || subGoals?.type == "Number" || subGoals?.type == "PieChart" || subGoals?.type == "TextField"  ) {
    subGoals = [subGoals]
    }



    if (subGoals[0]?.type == "TextField" && username == "ministryofjustice@sdgs.com") {
        subGoals = subGoals.filter(subGoal => subGoal.id == 33)
    }

    if (subGoals[0]?.type == "TextField" && username == "ministryofinternalsecurity@sdgs.com") {
        subGoals = subGoals.filter(subGoal => subGoal.id != 33)
    }

    const arrayOfSubGoals = subGoals

    const [ObjectValue, setObjectValue] = useState(null)
    const [ArrayValue, setArrayValue] = useState([])
    const [subGoalDetails, setSubGoalDetails] = useState(null)



    subGoals = subGoals.length > 0 ? subGoals.map(subGoal => {
        return {
            value: subGoal?.id,
            label: subGoal?.name
        }
    })
        : {
            value: subGoals?.id,
            label: subGoals?.name
        }


    const notify = (message) => toast.success(message);
    async function handleSubmit(e) {
        setLoading(true)
        e.preventDefault();
        const subGoalId = subGoals.length > 0 && Array.isArray(subGoals) ? subGoalDetails?.id : subGoals?.value
        await AxiosInstance.patch(`/sub-goals/${subGoalId}`, {
            data: subGoals.length > 0 && Array.isArray(subGoals) ? ArrayValue : [{ "value": ObjectValue }]
        }).then((response) => {
            setReRenderPage(true)
            setSubGoalsUpdated(true)
            setEditModalState(false)

        }).catch((err) => {
            console.log(err?.data);
            notify(err?.data?.message)
        })
    }

    const handleValueChangeForArraySubGoals = (index, year) => (e) => {
        const object = {
            year,
            value: isTextField ? e.target.value : parseInt(e.target.value)
        }
        let newArray = [...ArrayValue]
        newArray[index] = object
        setArrayValue(newArray)

    }



    useEffect(() => {
        if (subGoals.length > 0 && Array.isArray(subGoals)) {
            setSubGoalDetails(arrayOfSubGoals.filter(item => item.id == subGoalSelectValue)[0])
            setArrayValue(arrayOfSubGoals.filter(item => item.id == subGoalSelectValue)[0]?.data)
        }


    }, [subGoalSelectValue])

    useEffect(() => {
        if (subGoals.length > 0 && Array.isArray(subGoals) && arrayOfSubGoals?.some(subGoal => subGoal.id == 31 || subGoal.id == 32 || subGoal.id == 33)) {
            setIsTextField(true)

        }
    }, [subGoalSelectValue])

    return (
        <>
            <div class="fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                <ToastContainer />
                <div class="relative p-4 w-full max-w-md max-h-full">
                    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                                Update Indicators
                            </h3>
                            <button type="button" onClick={() => { setEditModalState(false); setReRenderPage(false) }} class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" >
                                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span class="sr-only">Close modal</span>
                            </button>
                        </div>
                        <form class="p-4 md:p-5">

                                    <div class="grid gap-4 mb-4 grid-cols-2">
                                        <div class="col-span-2 sm:col-span-2">
                                            <select id="category" onChange={(e) => setSubGoalSelectValue(e.target.value)} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-1.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                                                <option selected="">Select Indicator</option>
                                                {arrayOfSubGoals.map(subGoal => <option value={subGoal.id}>{subGoal.name}</option>)}
                                            </select>
                                        </div>
                                            <div class="col-span-1">
                                                <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Year</label>
                                                {subGoalDetails?.data.map(data => {
                                                    return (
                                                        <input
                                                            type="text" id="disabled-input-2"
                                                            aria-label="disabled input 2"
                                                            class={`${year == data?.year ? 'bg-blue-400 text-white' : 'bg-white border text-gray-900'} w-full border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-1.5 cursor-not-allowed dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                                            value={data?.year}
                                                            disabled readonly>
                                                        </input>
                                                    )
                                                })}
                                            </div>
                                        <div class="col-span-1 sm:col-span-1">
                                            <label for="value" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Value</label>
                                            {subGoalDetails?.data.map((data, index) => {
                                                return (
                                                    <input
                                                        type={`${isTextField ? 'text' : 'number'}`}
                                                        class={`${isTextField ? "w-full" : "w-1/2"} ${year == data?.year ? 'bg-blue-400 text-white' : 'bg-white border text-gray-900'} border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                                        value={ArrayValue[index]?.value}
                                                        min="0"
                                                        max="100"
                                                        defaultValue={data.value}
                                                        onChange={handleValueChangeForArraySubGoals(index, data?.year)}
                                                    >
                                                    </input>
                                                )
                                            })}
                                        </div>
                                    </div>
                            {loading ?
                                <img
                                    style={{ width: 60 }}
                                    src={reload}
                                    alt="reload"
                                />
                                :
                                <button type="submit" onClick={handleSubmit} class="text-white inline-flex items-center bg-blue-400 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    Update
                                </button>
                            }

                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
