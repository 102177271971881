import React, { useEffect, useState } from "react"
import edit from '../img/edit.svg';
import EditModal from "./EditModal";
import { useJwt } from "react-jwt";


export default function ChartCard({ colspan, children, goal, goalNumber, subGoals, setReRenderPage, setSubGoalsUpdated, col_start, itemCenter, staticChart, year }) {
    const [editModalState, setEditModalState] = useState(false)
    const [canView, setCanView] = useState(false)


    const token = localStorage.getItem("access_token")
    const { decodedToken, isExpired } = useJwt(token);
    const role = decodedToken?.role;
    const username = decodedToken?.username;
    

    useEffect(()=>{
    if(username === "ministryofbudget@sdgs.com" && Array.isArray(subGoals) && subGoals?.some(element => element.id == 2 || element.id == 3 || element.id == 4)){
        setCanView(true)
    }

    if(username === "ministryofhealth@sdgs.com" && Array.isArray(subGoals) && subGoals?.some(element => element.id == 5 || element.id == 7 || element.id == 8)){
        setCanView(true)
    }

    if(username === "ministryofhealth@sdgs.com" && Array.isArray(subGoals) && subGoals?.some(element => element.id == 10 || element.id == 11 || element.id == 12)){
        setCanView(true)
    }

    if(username === "ministryofagriculture@sdgs.com" && (subGoals?.id == 6 || subGoals?.id == 9)){
        setCanView(true)
    }

    if(username === "ministryofeducation@sdgs.com" && Array.isArray(subGoals) && subGoals?.some(element => element.id == 15 || element.id == 11 || element.id == 16)){
        setCanView(true)
    }

    if(username === "ministryofeducation@sdgs.com" && (subGoals?.id == 17 || subGoals?.id == 18 || subGoals?.id == 19)){
        setCanView(true)
    }

    if(username === "ministryofwomenaffairs@sdgs.com" && (subGoals?.id == 20 || subGoals?.id == 21)){
        setCanView(true)
    }

    if(username === "ministryofwaterresources@sdgs.com" && Array.isArray(subGoals) && subGoals?.some(element => element.id == 22 || element.id == 23)){
        setCanView(true)
    }

    if(username === "ministryofworks@sdgs.com" && (subGoals?.id == 24)){
        setCanView(true)
    }

    if(username === "ministryofyouth@sdgs.com" && (subGoals?.id == 25 || subGoals?.id == 26)){
        setCanView(true)
    }

    if(username === "ministryofbudget@sdgs.com" && Array.isArray(subGoals) && subGoals?.some(element => element.id == 27 || element.id == 28)){
        setCanView(true)
    }

    if(username === "ministryoflands@sdgs.com" && Array.isArray(subGoals) && subGoals?.some(element => element.id == 29 || element.id == 30)){
        setCanView(true)
    }

    if(username === "ministryofjustice@sdgs.com" && Array.isArray(subGoals) && subGoals?.some(element => element.id == 31 || element.id == 32 || element.id == 33)){
        setCanView(true)
    }

    if(username === "ministryofinternalsecurity@sdgs.com" && Array.isArray(subGoals) && subGoals?.some(element => element.id == 31 || element.id == 32 || element.id == 33)){
        setCanView(true)
    }

    if(staticChart)  setCanView(false)
}, [subGoals])

    return (
        <>
            {editModalState ? <EditModal subGoals={subGoals} setEditModalState={setEditModalState} setReRenderPage={setReRenderPage} setSubGoalsUpdated={setSubGoalsUpdated} username={username} year={year}/> : null}
            <div className={` shadow-md  sm:colspan-4 ${colspan} sm:col-start-1 ${col_start} flex flex-col w-full bg-white`} >
                <div className="mb-12 mt-4 px-5 flex flex-row justify-between items-center">
                    <div>
                        <h2 className="font-semibold text-gray-600 text-sm">{goalNumber}</h2>
                        <h2 className="font-bold mt-3">{goal}</h2>

                    </div>
                    {(role == "superAdmin" || canView) && !staticChart  ?
                       
                        <div className="cursor-pointer" onClick={() => setEditModalState(true)}>
                            <img
                                style={{ width: 30 }}
                                src={edit}
                                alt="edit"
                            />
                        </div>
                        : null
                    }
                </div>
                <div className="border-gray-100 border-b-2"></div>
                <div className={`px-5 w-full h-full flex flex-col justify-center ${itemCenter}`}>
                    {children}
                </div>
            </div>
        </>
    )
}