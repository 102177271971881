import React, { useState, useEffect } from "react";
import "../img/bg1.jpg";
import "./reusable/testimonial_slider/testimonial_slider.css";
import "../App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NavBar from "./NavBar";
import { useJwt } from "react-jwt";
import bg3 from '../img/bg3.jpg';
import location from "../img/location.png"
import email from "../img/email.png"
import phone from "../img/phone.png"
import ssa_image from "../img/maryam.jpg"
import he_kastina from "../img/HE_kastina.jpg"
import AboutUs from "./AboutUs";
import Footer from "./Footer";
import ContactUs from "./ContactUs";




export default function LandingPage({ history }) {
  const [goals, setGoals] = useState([]);
  const [aboutUsModal, setAboutUsModal] = useState(false);
  const [contactUsModal, setContactUsModal] = useState(false);



  const token = localStorage.getItem("access_token")
  const { decodedToken, isExpired } = useJwt(token);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [aboutUsModal, contactUsModal, decodedToken])

  return (
    <div className="h-full w-full">
      <NavBar username={decodedToken?.username} setAboutUsModal={setAboutUsModal} setContactUsModal={setContactUsModal} />
      {aboutUsModal ? <AboutUs setAboutUsModal={setAboutUsModal} /> : null}
      {contactUsModal ? <ContactUs setContactUsModal={setContactUsModal} /> : null}
      <div className="bg-gray-100  h-full w-full py-10 ">
        <div className="w-5/6 bg-white shadow rounded mx-auto px-10 py-10  h-full grid grid-cols-4 gap-10 md:flex-row text-black">
          <div className="border-b-4 border-gray-400 w-1/4 col-span-4 flex justify-center items-center mx-auto introduction font-bold text-2xl md:text-3xl text-center">Introduction</div>
          <div className="col-span-4 md:col-span-2 flex flex-col">
            <span className="font-normal text-lg">
              Welcome to the Katsina State Project Support Unit,
              Substainable Development Goals (SDG) Tracking Dashboard - your comprehensive portal
              for monitoring and evaluating the progress of our initiatives towards achieving the United Nations SDGs.
              This innovative dashboard serves as a transparent and dynamic platform, designed to empower citizens, policymakers,
              and stakeholders with real-time insights into the implementation and impact of our developmental programs.
            </span>
          </div>
          <div className="col-span-4 md:col-span-2">
            <span className="rounded">
              <img
                className="w-full rounded-md"
                src={bg3}
                alt="bg3"
              />
            </span>
          </div>

          <div className="border-b-4 border-gray-400 w-1/4 col-span-4 flex justify-center items-center mx-auto introduction font-bold text-2xl md:text-3xl text-center" id="about">About Us</div>

          {/* About us */}
          <div className="col-span-4 md:col-span-2">
            <div className="flex flex-col max-w-full md:flex-row justify-start">
              <div class="flex flex-col shadow h-[500px] w-full rounded-lg justify-center items-center">
                <img
                  src={he_kastina}
                  alt="he_kastina"
                  className="w-full h-5/6"
                />
                <span className="md:hidden">
                  <p className="text-lg font-sm">
                    Mallam Dikko Umaru Radda, Ph.D
                  </p>
                  <p className="font-semibold">Executive Governor Kastina State</p>
                </span>
              </div>
            </div>
          </div>

          <div class="hidden md:visible md:flex md:flex-col md:shadow md:h-[500px] md:w-full md:rounded-lg md:justify-center md:items-center md:mr-4 md:col-span-2">
            <span className="text-center">
              <p className="text-lg font-sm">
                Mallam Dikko Umaru Radda, Ph.D
              </p>
              <p className="text-xl font-semibold">Executive Governor Kastina State</p>
            </span>
          </div>


          <div className="col-span-4 md:col-span-2 flex flex-row md:flex-col justify-start  shadow px-1 pt-4">
            <div class="flex flex-col w-full rounded-lg justify-start items-center">
              <img
                src={ssa_image}
                alt="ssa_image"
                className="w-full"
              />
              <span className="w-full flex flex-col justify-center items-center">
                <p className="text-lg md:font-medium">
                  Barr. Maryam Hameeda Sodangi
                </p>
                <p className="text-sm font-semibold">SSA-SDGs & Development Partners</p>
              </span>
              <span className="md:hidden font-normal md:text-lg w-full px-2 mt-4 md:mt-10">
              Barrister Maryam Hameeda Sodangi is the current Senior Special Assistant to the Governor of Katsina State on Substainable
              Development Goals and Development Partners, serving concurrently as the Chief Executive Officer of the Katsina State Project Support Unit.
              Additionally, she holds the position of UNDP Technical Assistant to the Katsina State Government.
            </span>
            </div>
          </div>

          <div className="hidden  md:col-span-2 md:flex md:flex-col md:justify-center  md:shadow md:px-1 md:pt-4">
            <span className="font-normal md:text-lg w-full px-2 mt-4 md:mt-10">
              Barrister Maryam Hameeda Sodangi is the current Senior Special Assistant to the Governor of Katsina State on Substainable
              Development Goals and Development Partners, serving concurrently as the Chief Executive Officer of the Katsina State Project Support Unit.
              Additionally, she holds the position of UNDP Technical Assistant to the Katsina State Government.
            </span>
          </div>

          


          {/* Contact us */}
          <div className="border-b-4 border-gray-400 w-1/4 col-span-4 flex justify-center items-center mx-auto introduction font-bold text-2xl md:text-3xl text-center" id="contact">Contact Us</div>

          <div className="col-span-4 md:col-span-1 flex flex-col">
            <div className="flex-none w-full ">
              <span>
                <h3 class="introduction text-xl font-semibold text-gray-900 dark:text-white">
                  Katsina State Project Support Unit
                </h3>
              </span>
              <div className="py-3">
                <div className="flex flex-col">
                  <div className="flex flex-row mb-3">
                    <img
                      style={{ width: 30 }}
                      src={location}
                      alt="location"
                      className="mr-2 "
                    />
                    <span className="introduction text-lg ">Location:</span>
                  </div>
                  <span>Olusegun Obasanjo Drive, VIP Opp. Water Board GRA, Katsina, Katsina State.</span>
                </div>
              </div>
              <div className="py-3">
                <div className="flex flex-col">
                  <div className="flex flex-row  mb-3">
                    <img
                      style={{ width: 30 }}
                      src={email}
                      alt="email"
                      className="mr-2"
                    />
                    <span className="introduction text-lg ">Email:</span>
                  </div>
                  <span>info@sdgskatsina.org</span>
                </div>
              </div>
              <div className="py-3">
                <div className="flex flex-col">
                  <div className="flex flex-row mb-3">
                    <img
                      style={{ width: 30 }}
                      src={phone}
                      alt="phone"
                      className="mr-2"
                    />
                    <span className="introduction text-lg ">Call:</span>
                  </div>
                  <span>+234 814 957 8613</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-4 md:col-span-3">
            <div className="flex-grow">
              <iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Olusegun%20Obasanjo%20Drive,%20VIP%20Opp.%20Water%20Board%20GRA,%20Katsina,%20Katsina%20State+(Katsina%20State%20Government%20House)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                <a href="https://www.gps.ie/">gps devices</a>
              </iframe>
            </div>
          </div>
        </div>
      </div>
      <Footer setAboutUsModal={setAboutUsModal} setContactUsModal={setContactUsModal} />
    </div>
  );
}
