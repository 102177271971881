import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import school_building from '../img/school_building.svg';

const DoughnutChartWithMultipleDatasets = ({ arrayOfDoughnutLabels, data1, data2, backgroundColor }) => {
  const data = {
    labels: arrayOfDoughnutLabels,
    datasets: [
      {
        data: [data1, data2],
        backgroundColor: backgroundColor,
        borderWidth: 0
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: 60,
    rotation: -90,
    circumference: 360,
    plugins: {
      legend: {
        display: true,
        position: 'bottom'
      }
    },
    title: {
      display: false,
      text: 'Doughnut Chart with Two Datasets',
      fontSize: 16
    }
  };

  return (
    <div className='doughnut position-relative flex'>
      <Doughnut data={data} options={options} width={220} height={220} />
      <div className="s-green-bg position-absolute top-[25%] left-[43%] -z-20">
        <div className='position-absolute top-[25%] left-[25%]'>
          <img
            className='doughnut_image'
            src={school_building}
            alt="school_building"
          />
        </div>
      </div>
    </div>)
};

export default DoughnutChartWithMultipleDatasets;