import React, { useEffect, useState } from "react";
import "../img/bg1.jpg";
import "./reusable/testimonial_slider/testimonial_slider.css";
import "../App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import MultipleLineChart from './MultipleLineChart';
import StackedBarChart from './StackedBarChart';
import NavBar from "./NavBar";
import { MultilineDataSetFunction, RoundedBarChartDataSetFunction } from '../util/DataSetFunction';
import FullyRoundedBarChart from './FullyRoundedBarChart';
import DoughnutChartWithMultipleDatasets from "./DoughnutChartWithMultipleDatasets";
import CircularProgressBar from "./CircularProgressBar";
import HalfProgressBar from "./HalfProgressBar";
import teacher from '../img/teacher.png';
import obstetrics from '../img/obstetrics.svg';
import school_building_2 from '../img/school_building_2.svg';
import school_building_3 from '../img/school_building_3.svg';
import intimate from '../img/Intimate.png';
import energy from '../img/affordable_energy.png';
import success from "../img/success.svg"
import team_work from '../img/teamwork-bubble.svg';
import backbutton from '../img/backbutton.svg';
import people from '../img/people-group-solid-blu.svg';
import { useLocation } from 'react-router-dom';
import ChartCard from "./ChartCard";
import AxiosInstance from "./authorization/AxiosInstance";
import Toast from "../util/toast";
import FlashModal from "./FlashModal";
import { useHistory } from "react-router-dom";
import { values } from "lodash";
import Table1 from "./tables/Table1";
import Table2 from "./tables/Table2";
import Table3 from "./tables/Table3";
import Table11 from "./tables/Table11";
import Table7a from "./tables/Table7a";




export default function Visualization() {
    const [menuButtonState, setMenuButtonState] = useState(null)
    const [reRenderPage, setReRenderPage] = useState(false)
    const [subGoals, setSubGoals] = useState([])
    const [subGoalsUpdated, setSubGoalsUpdated] = useState(false)
    const [loading, setLoading] = useState(true);
    const [year, setYear] = useState(null);

    const history = useHistory()
    const location = useLocation();
    const username = location.state.user?.username;
    const goal = location.state.goal;
    const goalId = location.state.goalId;

    const override = {
        display: "block",
        margin: "0 auto",
        borderColor: "red",
    };

    const updateSubGoaltoastMessage = "Successful";

    async function getSubGoals() {
        await AxiosInstance.get(`/sub-goals/sdg/?sdgId=${goalId}`)
            .then((response) => {
                setSubGoals(response.data)
                setLoading(!loading)
            }).catch((err) => console.log(err.data))
    }

    const image1 =
        <img
            style={{ width: 30 }}
            src={school_building_2}
            alt="school_building_2"
        />


    const image2 =
        <img
            style={{ width: 60 }}
            src={teacher}
            alt="teacher"
        />


    const image3 =
        <img
            style={{ width: 30 }}
            src={school_building_3}
            alt="school_building_3"
        />

    const labels = ["2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032"]


    const multiLineChartColors = ["rgb(33, 181, 163)", "rgb(22, 91, 170)", "#f279a4"]
    const goal6Colors = ["rgb(219, 227, 254)", "rgb(255, 186, 227)"]
    const RoundedBarChart = ["rgb(22, 91, 170)", "#8bfcb3"]
    const StackedBarChartColors = ["rgb(22, 91, 170)", "#8ab1ff"]
    const DoughnutChartProperties = { "Schools with basic facilities": { "color": "rgb(126,144, 240)", "image": image1 }, "Teachers with the minimum required qualifications": { "color": "rgb(73,179, 179)", "image": image2 }, "Pupil-Teacher ratio": { "color": "rgb(255,102, 166)", "image": image3 } }


    // if (goal == "No Poverty") {
    let arrayOfDataSet1 = []
    let label1 = []
    subGoals.filter(subGoal => subGoal?.type === "LineChart").forEach((subGoal, index) => {
        subGoal?.data?.filter(record => record?.value != 0).forEach(record => {
            if (label1?.indexOf(record?.year) == -1) {
                label1.push(record?.year)
            }
        })
        arrayOfDataSet1.push(MultilineDataSetFunction(subGoal?.name, subGoal.data.map(data => data.value), multiLineChartColors[index], 0.5, false))
    
    })

    // if (goal == "Zero Hunger") {
    let arrayOfDataSet2 = []
    let specificSubGoals = [];
    let label2 = []
    subGoals.filter(subGoal => subGoal?.type === "BarChart").forEach((subGoal, index) => {
        subGoal?.data?.filter(record => record?.value != 0).forEach(record => {
            if (label2?.indexOf(record?.year) == -1) {
                label2.push(record?.year)
            }
        })
        specificSubGoals.push(subGoal)
        arrayOfDataSet2.push(RoundedBarChartDataSetFunction(subGoal?.name, subGoal.data.map(data => data.value), multiLineChartColors[index], 0, false))
    })

    // if (goal == "Good Health and Well-being") {
    let arrayOfDataSet3 = []
    let label3 = []
    subGoals.filter(subGoal => subGoal?.type === "BarChart").forEach((subGoal, index) => {
        subGoal?.data?.filter(record => record?.value != 0).forEach(record => {
            if (label3?.indexOf(record?.year) == -1) {
                label3.push(record?.year)
            }
        })
        arrayOfDataSet3.push(RoundedBarChartDataSetFunction(subGoal?.name, subGoal.data.map(data => data.value), multiLineChartColors[index], 0, false))
    })


    // Quality Education
    let arrayOfDoughnutLabels_men = []
    let data_men = []
    let subGoalsForDoughnutChart_men = []
    subGoals.filter(subGoal => subGoal?.type === "PieChart" && subGoal?.name === "Percentage of literate men").forEach((subGoal, index) => {
        arrayOfDoughnutLabels_men.push(subGoal?.name)
        data_men.push(subGoal?.data.filter(subGoal => subGoal.year === year)[0]?.value)
        subGoalsForDoughnutChart_men.push(subGoal)
    })

    let arrayOfDoughnutLabels_women = []
    let data_women = []
    let subGoalsForDoughnutChart_women = []
    subGoals.filter(subGoal => subGoal?.type === "PieChart" && subGoal?.name === "Percentage of literate women").forEach((subGoal, index) => {
        arrayOfDoughnutLabels_women.push(subGoal?.name)
        data_women.push(subGoal?.data.filter(subGoal => subGoal.year === year)[0]?.value)
        subGoalsForDoughnutChart_women.push(subGoal)
    })

    // if (goal == "Reduced Inequality") {
    const arrayOfDataSet8 = []
    let label10 = []
    let subGoalsForRoundedBarChart = []
    subGoals.map((subGoal, index) => {
        subGoal?.data?.filter(record => record?.value != 0).forEach(record => {
            if (label10?.indexOf(record?.year) == -1) {
                label10.push(record?.year)
            }
        })
        subGoalsForRoundedBarChart.push(subGoal)
        arrayOfDataSet8.push(RoundedBarChartDataSetFunction(subGoal?.name, subGoal.data.map(data => data.value), RoundedBarChart[index], 0, false))
    })

    // if (goal == "Sustainable Cities and Communities") {
    let arrayOfDataSet9 = []
    let subGoalsForStackedBarChart = []
    let label11 = []
    subGoals.filter(subGoal => subGoal?.type === "StackedBarChart").forEach((subGoal, index) => {
        subGoal?.data?.filter(record => record?.value != 0).forEach(record => {
            if (label11?.indexOf(record?.year) == -1) {
                label11.push(record?.year)
            }
        })
        subGoalsForStackedBarChart.push(subGoal)
        arrayOfDataSet9.push(RoundedBarChartDataSetFunction(subGoal?.name, subGoal.data.map(data => data.value), StackedBarChartColors[index], StackedBarChartColors[index]))
    })

    // if (goal == "Clean Water and Sanitation") {
    let arrayOfDataSet10 = []
    subGoals.filter(subGoal => subGoal?.type === "LineChart").forEach((subGoal, index) => {
        arrayOfDataSet10.push(MultilineDataSetFunction(subGoal?.name, subGoal.data.map(data => `${data.value}%`), multiLineChartColors[0], 0.5, true))
    })


    const GraphicCard = ({ number }) => {
        return (
            <div class="max-w-sm  bg-gray-200 border border-gray-200 rounded-lg  dark:bg-gray-800 dark:border-gray-700">
                <h5 class=" mx-auto p-2 text-3xl px-2 font-semibold text-gray-900 dark:text-white">{formatNumberWithCommas(number)}</h5>
            </div>)
    }

    function formatNumberWithCommas(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }


    useEffect(() => {
        getSubGoals()
        setYear(location.state.year.toString())
    }, [reRenderPage])


    return (
        <div className=" w-full position-relative bg-gray-100 pb-10">
            <NavBar menuButtonState={menuButtonState} username={username} />
            {subGoalsUpdated ?
                <FlashModal message={updateSubGoaltoastMessage} setSubGoalsUpdated={setSubGoalsUpdated} setReRenderPage={setReRenderPage}>
                    <img
                        src={success}
                        alt="success"
                    />
                </FlashModal>
                : null}
            <div className="pl-10 w-full py-8 bg-gray-100 font-bold text-2xl">
                Dashboard {`(${year})`}
            </div>
            <div className="w-full flex items-center">
                <div className="w-full flex flex-col md:flex-row">
                    <div
                        className={`flex flex-col justify-center mx-auto w-full`}
                    >
                        <div className="w-1/2 md:w-1/6 px-10 flex items-center py-2 cursor-pointer" onClick={() => history.goBack()}>
                            <div>
                                <img
                                    src={backbutton}
                                    alt="backbutton"
                                    className="w-8"
                                />
                            </div>
                            <div className="text-sm md:text-xl font-bold ml-2">Go Back</div>
                        </div>
                        <div className="grid-cols-1 gap-2 grid md:grid-cols-2 md:gap-4 lg:grid-cols-3 lg:gap-6 xl:grid-cols-4 xl:gap-8  px-10 w-full mx-auto">
                            {goal == "No Poverty" ?
                                <>
                                    <div className="grid grid-cols-subgrid gap-4 col-span-4">
                                        <ChartCard colspan="md:col-span-2" col_start="md:col-start-2" goal={goal} goalNumber="Goal 1" subGoals={subGoals} setReRenderPage={setReRenderPage} setSubGoalsUpdated={setSubGoalsUpdated} itemCenter={"items-center"} year={year}>
                                            <div className="h-full w-full">
                                                <MultipleLineChart arrayOfDataSetFunction={arrayOfDataSet1} labels={label1} />
                                            </div>
                                        </ChartCard>
                                    </div>
                                </>
                                : null
                            }

                            {goal == "Zero Hunger" ?
                                <>
                                    <ChartCard colspan="md:col-span-2" goal={goal} goalNumber="Goal 2" subGoals={specificSubGoals} setReRenderPage={setReRenderPage} setSubGoalsUpdated={setSubGoalsUpdated} itemCenter={"items-center"} year={year}>
                                        <FullyRoundedBarChart ArrayOfRoundedBarChartDataSet={arrayOfDataSet2} y_axis_display={false} labels={label2} />
                                    </ChartCard>

                                    {subGoals.map((subGoal, index) => {
                                        const value = subGoal?.data.filter(subGoal => subGoal.year === year)[0]?.value
                                        return (
                                            <>
                                                {subGoal?.type === "DoughnutChart" ?
                                                    <ChartCard colspan="md:col-span-1" goal={goal} goalNumber="Goal 2" subGoals={subGoal} setReRenderPage={setReRenderPage} setSubGoalsUpdated={setSubGoalsUpdated} itemCenter={"items-center"} year={year}>
                                                        <CircularProgressBar
                                                            title={subGoal?.name}
                                                            trail_value={100}
                                                            trail_color={`rgb(152,148, 198)`}
                                                            trail_strokeWidth={12}
                                                            main_path_value={value}
                                                            main_path_color={`rgb(82,78, 129)`}
                                                            main_strokeWidth={12}
                                                            rotation={0.45}
                                                            strokeWidth={20}
                                                            stroke_line_cap="butt"
                                                        >
                                                            <h1 className="text-lg font-bold"> {`${value}%`} </h1>
                                                        </CircularProgressBar>
                                                    </ChartCard>


                                                    : subGoal?.type === "HalfDoughnutChart" ?
                                                        <ChartCard colspan="md:col-span-1" goal={goal} goalNumber="Goal 2" subGoals={subGoal} setReRenderPage={setReRenderPage} setSubGoalsUpdated={setSubGoalsUpdated} itemCenter={"items-center"} year={year}>
                                                            <HalfProgressBar
                                                                title={subGoal?.name}
                                                                circle_content={`${value}%`}
                                                                path_color={`rgb(33, 181, 163)`}
                                                                main_path_value={value}
                                                            />
                                                        </ChartCard>
                                                        : null}
                                            </>
                                        )
                                    })}

                                </> : null
                            }

                            {goal == "Good Health and Well-being" ?
                                <>
                                    <ChartCard colspan="md:col-span-2" goal={goal} goalNumber="Goal 3" subGoals={specificSubGoals} setReRenderPage={setReRenderPage} setSubGoalsUpdated={setSubGoalsUpdated} itemCenter={"items-center"} year={year}>
                                        <FullyRoundedBarChart ArrayOfRoundedBarChartDataSet={arrayOfDataSet3} y_axis_display={false} labels={label3} />
                                    </ChartCard>

                                    {subGoals.map((subGoal, index) => {
                                        const value = subGoal?.data.filter(subGoal => subGoal.year === year)[0]?.value
                                        return (
                                            <>
                                                {subGoal?.type === "DoughnutChart" ?
                                                    <ChartCard colspan="md:col-span-1" goal={goal} goalNumber="Goal 3" subGoals={subGoal} setReRenderPage={setReRenderPage} setSubGoalsUpdated={setSubGoalsUpdated} itemCenter={"items-center"} year={year}>
                                                        <CircularProgressBar
                                                            title={subGoal?.name}
                                                            trail_value={100}
                                                            trail_color="#eee"
                                                            trail_strokeWidth={12}
                                                            main_path_value={value}
                                                            main_path_color={`rgb(126,144, 240)`}
                                                            main_strokeWidth={12}
                                                            rotation={0.1}
                                                        >
                                                            <img
                                                                style={{ width: 30 }}
                                                                src={obstetrics}
                                                                alt="obstetrics"
                                                            />
                                                        </CircularProgressBar>
                                                    </ChartCard>
                                                    : subGoal?.type === "Number" ?
                                                        <ChartCard colspan="md:col-span-1" goal={goal} goalNumber="Goal 3" subGoals={subGoal} setReRenderPage={setReRenderPage} setSubGoalsUpdated={setSubGoalsUpdated} itemCenter={"items-center"} year={year}>
                                                            <div className="my-20 h-full flex flex-col  items-center justify-center">
                                                                <GraphicCard number={value} />
                                                                <h2 className="mt-2 text-center">Health density per 100, 000 population</h2>
                                                            </div>
                                                        </ChartCard>
                                                        : null
                                                }

                                            </>
                                        )
                                    })}

                                </>
                                : null
                            }
                            {goal == "Quality Education" ?
                                <>
                                    <ChartCard colspan="md:col-span-1" goal={goal} goalNumber="Goal 4" subGoals={subGoalsForDoughnutChart_men} setReRenderPage={setReRenderPage} setSubGoalsUpdated={setSubGoalsUpdated} itemCenter={"items-center"} year={year}>
                                        <div className="pt-10 md:mt-0 w-full" >
                                            <DoughnutChartWithMultipleDatasets arrayOfDoughnutLabels={arrayOfDoughnutLabels_men} data1={data_men[0]} data2={100 - data_men[0]} backgroundColor={['rgb(22, 91, 170)', '#e1e4e4']} />
                                        </div>
                                    </ChartCard>
                                    <ChartCard colspan="md:col-span-1" goal={goal} goalNumber="Goal 4" subGoals={subGoalsForDoughnutChart_women} setReRenderPage={setReRenderPage} setSubGoalsUpdated={setSubGoalsUpdated} itemCenter={"items-center"} year={year}>
                                        <div className="pt-10 md:mt-0 w-full" >
                                            <DoughnutChartWithMultipleDatasets arrayOfDoughnutLabels={arrayOfDoughnutLabels_women} data1={data_women[0]} data2={100 - data_women[0]} backgroundColor={['rgb(255,102, 166)', '#e1e4e4']} />
                                        </div>
                                    </ChartCard>
                                    {subGoals.map((subGoal, index) => {
                                        const value = subGoal?.data.filter(subGoal => subGoal.year === year)[0]?.value
                                        return (
                                            <>
                                                {subGoal?.type === "DoughnutChart" ?
                                                    <ChartCard colspan="md:col-span-1" goal={goal} goalNumber="Goal 4" subGoals={subGoal} setReRenderPage={setReRenderPage} setSubGoalsUpdated={setSubGoalsUpdated} itemCenter={"items-center"} year={year}>
                                                        <CircularProgressBar
                                                            title={subGoal?.name}
                                                            trail_value={100}
                                                            trail_color="#eee"
                                                            trail_strokeWidth={12}
                                                            main_path_value={value}
                                                            main_path_color={DoughnutChartProperties[subGoal?.name]["color"]}
                                                            main_strokeWidth={12}
                                                            rotation={0.1}
                                                            stroke_line_cap="round"
                                                        >
                                                            {DoughnutChartProperties[subGoal?.name]["image"]}
                                                        </CircularProgressBar>
                                                    </ChartCard>
                                                    : null}
                                            </>
                                        )
                                    }
                                    )}
                                </>
                                : null
                            }


                            {goal == "Gender Equality" ?
                                <div className="grid md:grid-cols-subgrid gap-4 col-span-4 position-relative">
                                    {subGoals.map((subGoal, index) => {
                                        const value = subGoal?.data.filter(subGoal => subGoal.year === year)[0]?.value
                                        return (
                                            <>
                                                {subGoal?.type === "HalfDoughnutChart" ?
                                                    <ChartCard colspan="md:col-span-1" col_start="md:col-start-2" goal={goal} goalNumber="Goal 5" subGoals={subGoal} setReRenderPage={setReRenderPage} setSubGoalsUpdated={setSubGoalsUpdated} itemCenter={"items-center"} year={year}>
                                                        <HalfProgressBar
                                                            title={subGoal?.name}
                                                            circle_content={`${value}%`}
                                                            path_color={`rgb(33, 181, 163)`}
                                                            main_path_value={value}
                                                        />
                                                    </ChartCard>
                                                    : subGoal?.type === "DoughnutChart" ?
                                                        <ChartCard colspan="md:col-span-1" goal={goal} goalNumber="Goal 5" subGoals={subGoal} setReRenderPage={setReRenderPage} setSubGoalsUpdated={setSubGoalsUpdated} itemCenter={"items-center"} year={year}>
                                                            <CircularProgressBar
                                                                title={subGoal?.name}
                                                                circle_content="60%"
                                                                trail_value={100}
                                                                trail_color="#eee"
                                                                trail_strokeWidth={12}
                                                                main_path_value={value}
                                                                main_path_color={`rgb(255,102, 166)`}
                                                                main_strokeWidth={12}
                                                                rotation={0.1}
                                                                stroke_line_cap="butt"
                                                            >
                                                                <img
                                                                    style={{ width: 90, height: 90 }}
                                                                    src={intimate}
                                                                    alt="intimate"
                                                                />
                                                            </CircularProgressBar>
                                                        </ChartCard>
                                                        : null
                                                }
                                            </>
                                        )
                                    })}

                                </div>
                                : null
                            }

                            {goal === "Clean Water and Sanitation" ?
                                <>
                                    {
                                        subGoals.map((subGoal, index) => {
                                            const arrayOfDataSet = MultilineDataSetFunction(subGoal?.name, subGoal.data.map(data => data.value), goal6Colors[index], 0.5, true)
                                            const label = []
                                            subGoal?.data?.filter(record => record?.value != 0).forEach(record => {
                                                if (label?.indexOf(record?.year) == -1) {
                                                    label.push(record?.year)
                                                }
                                            })
                                            return (
                                                <>
                                                    <ChartCard colspan="md:col-span-2" goal={goal} goalNumber="Goal 6" subGoals={subGoal} setReRenderPage={setReRenderPage} setSubGoalsUpdated={setSubGoalsUpdated} itemCenter={"items-center"} year={year}>
                                                        <MultipleLineChart arrayOfDataSetFunction={[arrayOfDataSet]} maintainAspectRatio={true} labels={label} />
                                                    </ChartCard>
                                                </>
                                            )
                                        }
                                        )
                                    }
                                </>
                                : null}

                            {goal == "Affordable and Clean Energy" ?
                                <>
                                    {subGoals.map((subGoal, index) => {
                                        const value = subGoal?.data.filter(subGoal => subGoal.year === year)[0]?.value
                                        return (
                                            <div className="grid md:grid-cols-subgrid gap-4 col-span-4">
                                                <ChartCard colspan="md:col-span-1" col_start="md:col-start-2" goal={goal} goalNumber="Goal 7" subGoals={subGoal} setReRenderPage={setReRenderPage} setSubGoalsUpdated={setSubGoalsUpdated} itemCenter={"items-center"} year={year}>
                                                    <CircularProgressBar
                                                        title={subGoal?.name}
                                                        trail_value={100}
                                                        trail_color="#eee"
                                                        trail_strokeWidth={12}
                                                        main_path_value={value}
                                                        main_path_color={`rgb(102,136, 255)`}
                                                        main_strokeWidth={12}
                                                        rotation={0.1}
                                                        stroke_line_cap="round"
                                                    >
                                                        <img
                                                            style={{ width: 30 }}
                                                            src={energy}
                                                            alt="energy"
                                                        />
                                                    </CircularProgressBar>
                                                </ChartCard>

                                                <ChartCard colspan="md:col-span-1" goal={goal} goalNumber="Goal 7" subGoals={subGoal} setReRenderPage={setReRenderPage} setSubGoalsUpdated={setSubGoalsUpdated} itemCenter={"items-center"} year={year} staticChart={true}>
                                                    <div className="w-full flex items-center flex-col justify-center">

                                                        <img
                                                            style={{ width: 90 }}
                                                            src={people}
                                                            alt="people"
                                                        />
                                                        <h2 className="mt-2 font-bold text-3xl"> 200, 000</h2>
                                                    </div>
                                                </ChartCard>
                                            </div>
                                        )
                                    }
                                    )}

                                </>
                                : null}


                            {goal == "Decent Work and Economic Growth" ?
                                <>
                                    {subGoals.map((subGoal) => {
                                        const label = []
                                        subGoal?.data?.filter(record => record?.value != 0).forEach(record => {
                                            if (label?.indexOf(record?.year) == -1) {
                                                label.push(record?.year)
                                            }
                                        })
                                        const arrayOfDataSet = MultilineDataSetFunction(subGoal?.name, subGoal.data.map(data => data.value), goal6Colors[0], 0.5, true)
                                        const value = subGoal?.data.filter(subGoal => subGoal.year === year)[0]?.value
                                        return (
                                            <>
                                                {subGoal?.type === "LineChart" ?
                                                    <ChartCard colspan="md:col-span-3" goal={goal} goalNumber="Goal 8" subGoals={subGoal} setReRenderPage={setReRenderPage} setSubGoalsUpdated={setSubGoalsUpdated} itemCenter={"items-center"} year={year}>
                                                        <MultipleLineChart arrayOfDataSetFunction={[arrayOfDataSet]} maintainAspectRatio={false} labels={label} />
                                                    </ChartCard>
                                                    : subGoal?.type === "DoughnutChart" ?
                                                        <ChartCard colspan="md:col-span-1" goal={goal} goalNumber="Goal 8" subGoals={subGoal} setReRenderPage={setReRenderPage} setSubGoalsUpdated={setSubGoalsUpdated} itemCenter={"items-center"} year={year}>
                                                            <CircularProgressBar
                                                                title={subGoal?.name}
                                                                circle_content="60%"
                                                                trail_value={100}
                                                                trail_color="#eee"
                                                                trail_strokeWidth={12}
                                                                main_path_value={value}
                                                                main_path_color={"#7BDAE2"}
                                                                main_strokeWidth={12}
                                                                rotation={0.1}
                                                                stroke_line_cap="round"
                                                            >
                                                                <img
                                                                    style={{ width: 50 }}
                                                                    src={team_work}
                                                                    alt="team_work"
                                                                />
                                                            </CircularProgressBar>
                                                        </ChartCard>
                                                        : null}
                                            </>
                                        )
                                    })}

                                </>
                                : null
                            }


                            {goal == "Reduced Inequality" ?
                                <div className="grid grid-cols-subgrid gap-4 col-span-4">
                                    <ChartCard colspan="md:col-span-2" col_start="md:col-start-2" goal={goal} goalNumber="Goal 10" subGoals={subGoalsForRoundedBarChart} setReRenderPage={setReRenderPage} setSubGoalsUpdated={setSubGoalsUpdated} itemCenter={"items-center"} year={year}>
                                        <FullyRoundedBarChart ArrayOfRoundedBarChartDataSet={arrayOfDataSet8} labels={label10} />
                                    </ChartCard>
                                </div>
                                : null}



                            {goal === "Sustainable Cities and Communities" ?
                                <div className="grid md:grid-cols-subgrid gap-4 col-span-4">
                                    <ChartCard colspan="md:col-span-2" col_start="md:col-start-2" goal={goal} goalNumber="Goal 11" subGoals={subGoalsForStackedBarChart} setReRenderPage={setReRenderPage} setSubGoalsUpdated={setSubGoalsUpdated} itemCenter={"items-center"} year={year}>
                                        <StackedBarChart ArrayOfStackedBarChartDataSet={arrayOfDataSet9} labels={label11} />
                                    </ChartCard>
                                </div>
                                : null}

                            {goal === "Peace, Justice, and Strong Institutions" ?
                                <div className="grid md:grid-cols-subgrid gap-4 col-span-4">
                                    <ChartCard colspan="md:col-span-2" col_start="md:col-start-2" goal={goal} goalNumber="Goal 16" subGoals={subGoals} setReRenderPage={setReRenderPage} setSubGoalsUpdated={setSubGoalsUpdated} year={year}>
                                        {subGoals.map(subGoal => {
                                            return (
                                                <div className="flex flex-row">
                                                    <form class="max-w-sm align-middle">
                                                        <div class="mb-5 flex flex-col justify-start items-start">
                                                            <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{subGoal?.name}</label>
                                                            <input type="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" disabled required value={subGoal?.data.filter(subGoal => subGoal.year === year)[0].value} />
                                                        </div>
                                                    </form>
                                                </div>
                                            )
                                        })}
                                    </ChartCard>
                                </div>
                                : null}

                        </div >
                        <br />
                    </div>
                </div>
            </div>
            {goal == "No Poverty" ?
                <>
                    <div className="w-auto px-10 ">
                        <h2 className="font-bold text-center mb-2">D6. Multidimensional poverty by State and Senatorial district (Nigeria MPI),  2021</h2>
                        <div className="w-auto scroller px-10">
                            <Table1 />
                        </div>
                    </div>
                </>
                : null}

            {goal == "Gender Equality" ?
                <>
                    <div className="w-auto px-10 mt-4 ">
                        <h2 className="font-bold text-center mb-2">Table LN.2.8: Parity indices (Continued)</h2>
                        <div className="w-auto scroller px-10">
                            <Table2 />
                        </div>
                    </div>
                </>
                : null}

            {goal == "Affordable and Clean Energy" ?
                <>
                    <div className="w-auto px-10 mt-4 ">
                        <h2 className="font-bold text-center mb-2">Results of household, women's, men's, under-5's and children age 5-17's interviews results, by area of residence (MICS, 2021; page 23)</h2>
                        <div className="w-auto scroller px-10 mb-20">
                            <Table7a />
                        </div>
                        <h2 className="font-bold text-center mb-2">Table SR.2.1: Housing characteristics (Continued)</h2>
                        <div className="w-auto scroller px-10">
                            <Table3 />
                        </div>
                    </div>
                </>
                : null}
            {goal == "Sustainable Cities and Communities" ?
                <>
                    <div className="w-auto px-10 mt-4 ">
                        <h2 className="font-bold text-center mb-2">B. Percentage of the population with access to safe and affordable housing</h2>
                        <div className="w-auto scroller px-10">
                            <Table11 />
                        </div>
                    </div>
                </>
                : null}
        </div >
    );
}
