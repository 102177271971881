import { CircularProgressbar, buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";


export default function HalfProgressBar({ title, circle_content, path_color, main_path_value }) {
    return (
                <div className="h-full position-relative flex flex-col justify-center items-center">
                    <div>
                    <CircularProgressbarWithChildren
                        value={main_path_value}
                        circleRatio={0.5}
                        strokeWidth={15}
                        styles={buildStyles({
                            rotation: 0.75,
                            // rotation: 1 / 2 + 1 / 8,
                            strokeLinecap: "butt",
                            textSize: "30px",
                            pathTransitionDuration: 0.5,
                            pathColor: path_color,
                            textColor: "#f88",
                            trailColor: "#eee"
                        })}
                    >
                        <div className="position-absolute flex flex-col justify-center items-center">
                            <h1 className="text-2xl font-bold"> {circle_content} </h1>
                        </div>
                    </CircularProgressbarWithChildren>
                    </div>
                    <p className="position-absolute text-center bottom-16 text-sm ">{title}</p>
                </div> 
    )
}