import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';

import './customCircularProgressBar.css';

export default function CircularProgressBar({
    title,
    trail_color,
    trail_strokeWidth,
    main_path_color,
    main_path_value,
    rotation,
    stroke_line_cap,
    children
}) {


    return (
        <div className="progress-container">
      <div className="progressbar-wrapper">
        <div style={{ width: 180, height: 180, margin: 60}}>
            <CircularProgressbarWithChildren
                value={main_path_value}
                strokeWidth={trail_strokeWidth}
                styles={buildStyles({
                    rotation: rotation,
                    pathColor: main_path_color,
                    trailColor: trail_color,
                    strokeLinecap: stroke_line_cap,
                })}
            >
                <div className="s-green-bg position-absolute flex flex-col justify-center items-center">
                    {/* <h1 className="text-lg font-bold"> {circle_content} </h1> */}
                    {/* <img
                        style={{ width: 40 }}
                        src={teacher}
                        alt="teacher"
                    /> */}
                    {children}
                </div>
            </CircularProgressbarWithChildren>
            <p className="text-sm title text-center pl-1">{title}</p>
        </div>
        </div>
        </div>
    )
}