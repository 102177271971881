import React, { useState, useEffect } from "react";
import "../img/bg1.jpg";
import "./reusable/testimonial_slider/testimonial_slider.css";
import "../App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NavBar from "./NavBar";
import { useHistory } from "react-router-dom";
import { useJwt } from "react-jwt";
import AxiosInstance from "./authorization/AxiosInstance";
import Goal1 from '../img/Goal-01.webp';
import Goal2 from '../img/Goal-02.webp';
import Goal3 from '../img/Goal-03.webp';
import Goal4 from '../img/Goal-04.webp';
import Goal5 from '../img/Goal-05.webp';
import Goal6 from '../img/Goal-06.webp';
import Goal7 from '../img/Goal-07.webp';
import Goal8 from '../img/Goal-08.webp';
import Goal10 from '../img/Goal-10.webp';
import Goal11 from '../img/Goal-11.webp';
import Goal16 from '../img/Goal-16.webp';
import AboutUs from "./AboutUs";
import Footer from "./Footer";
import ContactUs from "./ContactUs";
import HashLoader from "react-spinners/HashLoader";




export default function Home({ history }) {
  const [goals, setGoals] = useState([]);
  const [aboutUsModal, setAboutUsModal] = useState(false);
  const [contactUsModal, setContactUsModal] = useState(false);
  const [homePage, setHomePage] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingCompleted, setLoadingCompleted] = useState(false);
  const [color, setColor] = useState("#f279a4");
  const [year, setYear] = useState(new Date().getFullYear());

  const onViewClickHistory = useHistory();


  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  async function getSdgs() {
    await AxiosInstance.get("/sdgs").then((response) => {
      setGoals(response.data)
      setLoading(false)
      setLoadingCompleted(true)
    }).catch(err => {
      console.log(err)
    })
  }

  let years = ['2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030', '2031', '2032']

  const token = localStorage.getItem("access_token")
  const { decodedToken, isExpired } = useJwt(token);
  const Goals = [Goal1, Goal2, Goal3, Goal4, Goal5, Goal6, Goal7, Goal8, Goal10, Goal11, Goal16]

  function handleYearChange(e) {
    e.preventDefault()
    setYear(e.target.value)

  }

  useEffect(() => {
    getSdgs()
  }, [])


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [aboutUsModal, contactUsModal])

  return (
    <div className={`${loading ? 'h-screen w-full' : 'h-full w-full'} `}>
      <NavBar username={decodedToken?.username} setAboutUsModal={setAboutUsModal} setContactUsModal={setContactUsModal} homePage={homePage} />
      {aboutUsModal ? <AboutUs setAboutUsModal={setAboutUsModal} /> : null}
      {contactUsModal ? <ContactUs setContactUsModal={setContactUsModal} /> : null}
      <div className="h-full w-full bg px-10 py-5 flex items-center">
        <div className="w-full h-full flex flex-col md:flex-row">
          <div className="homepage-loader">
            <HashLoader
              color={color}
              loading={loading}
              cssOverride={override}
              size={250}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
          <div className="flex flex-col md:flex-row justify-between">
          <div className="yearCardSmallScreen mb-3">
            { loadingCompleted ?
            <form class="w-1/3 bg-blue-400 p-3 rounded-lg text-center">
              <label for="countries" class="block mb-2 text-xm font-medium text-white dark:text-white">Select a Year</label>
              <select onChange={handleYearChange} id="countries" class=" text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option selected>Choose a year</option>
                {years.map(year => <option value={year}>{year}</option>)}
              </select>
            </form>
            : null}
          </div>
            <div class="grid grid-cols-1 gap-4 md:grid-cols-4 md:gap-4 w-full md:w-3/4  h-full">
              {goals.map((goal, index) => (
                <button onClick={(e) => {
                  onViewClickHistory.push("/visualization", { user: decodedToken, subGoals: goal?.subGoals, goal: goal?.name, goalId: goal?.id, year: year });
                }} className="goalsCard bg-gray-200 rounded shadow-lg flex flex-row justify-evenly items-center">
                  <div>
                    <img
                      className="w-full"
                      src={Goals[index]}
                      alt="goal"
                    />
                  </div>
                </button>
              ))}
            </div> 
            <div className="yearCardMediumScreen">
            { loadingCompleted ?
            <form class="max-w-sm h-['20%'] bg-blue-400 p-10 rounded-lg text-center">
              <label for="countries" class="block mb-2 text-xl font-medium text-white dark:text-white">Select a Year</label>
              <select onChange={handleYearChange} id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option selected>Choose a year</option>
                {years.map(year => <option value={year}>{year}</option>)}
              </select>
            </form>
            : null}
          </div>
          </div>
          <br />
        </div>
      </div>
      <Footer setAboutUsModal={setAboutUsModal} setContactUsModal={setContactUsModal} homePage={homePage} />
    </div>
  );
}
