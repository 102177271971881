import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import "../img/bg1.jpg";
import "../App.css";
import NavBar from "./NavBar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useHistory } from "react-router-dom";
import IsCurrentUser from "./authorization/IsCurrentUser";
import { BasedURL } from "../util/constants";
import AxiosInstance from "./authorization/AxiosInstance";


import {
    Col,
    Row,
    Form,
    Button,
    Popover,
    OverlayTrigger,
} from "react-bootstrap";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";

export default function Home({ history }) {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setloading] = useState("");
    const [showWelcomeMsg, setShowWelcomeMsg] = useState(false);
    const [hasLoginError, setHasLoginError] = useState(false);
    const [loginErrorMessage, setLoginErrorMessage] = useState(null);
    const [goals, setGoals] = useState([]);
    const [aboutUsModal, setAboutUsModal] = useState(false);
    const [contactUsModal, setContactUsModal] = useState(false);



    const onViewClickHistory = useHistory();
    const routeChange = () => {
        let path = `home`;
        onViewClickHistory.push(path);
    }


    function onChangeEmail(e) {
        setEmail(e.target.value);
    }

    function onChangePassword(e) {
        setPassword(e.target.value);
    }

    const formStyle = {
        "border-radius": "5px",
        "background-color": "white",
        //   opacity: "0.8",
        color: "white",
        padding: "2.5rem",
    };



    async function handleSubmit(e) {
        e.preventDefault()
        await axios.post(`${BasedURL}/auth/login`, {
            username: email,
            password
        }).then(response => {
            localStorage.setItem("access_token", response.data.access_token)
            if (response) {
                routeChange()
            }
            setHasLoginError(false)
        }).catch(error => {
            setHasLoginError(true);
            setLoginErrorMessage(error?.response?.data?.message)
        })
        // }).catch(error => alert(error?.response?.data?.error))
    }

    async function getSdgs() {
        await AxiosInstance.get("/sdgs").then((response) => {
            setGoals(response.data)
        }).catch((err)=>{
            console.log(err)
        })
    }

    useEffect(() => {
        setTimeout(() => {
            setShowWelcomeMsg(true);
        }, 1000);
    }, [showWelcomeMsg]);


    useEffect(() => {
        getSdgs()
    }, [])

    if (IsCurrentUser()) {
        return <Redirect to='home' />
    }
    return (
        <div className="max-h-screen w-full position-relative">
            <NavBar setAboutUsModal={setAboutUsModal} setContactUsModal={setContactUsModal}/>
            <div className="flex justify-center items-center">
            { aboutUsModal ?  <AboutUs  setAboutUsModal={setAboutUsModal}/> : null }
            { contactUsModal ?  <ContactUs  setContactUsModal={setContactUsModal}/> : null }
            </div>
            <div className="max-h-screen w-full bg1 flex items-center">
                <div className="w-full flex flex-col md:flex-row  space-x-20">
                    <div
                        className={`w-full md:w-1/2 flex flex-col justify-center mt-5 pl-[6%] md:pl-[11%] `}
                        style={{ height: "100%", paddingTop: "4rem" }}
                    >
                        <div>
                            <h1
                                style={{
                                    color: "white",
                                    fontSize: "46px",
                                    fontWeight: "700",
                                }}
                                className={`${showWelcomeMsg ? "translate-x-0" : "-translate-x-full "
                                    } ease-in-out transition-all duration-1000`}
                            >
                                {/* <div>
                                    <img
                                        style={{ width: 120 }}
                                        src={katsina_state_logo2}
                                        alt="katsina_state_logo2"
                                    />
                                </div> */}
                                <span className="heading white-heading">Katsina State </span> <br /> <br />{" "}
                                <span className="">Sustainable Development Goals Tracking System</span>
                            </h1>
                        </div>
                        <div
                            className={` ${showWelcomeMsg && "hidden"
                                } md:ml-24 md:-mt-24 bg-white w-10 h-10 rounded-full transition-all animate-ping`}
                        ></div>
                    </div>
                    <div
                        className=" mx-auto w-11/12 md:w-1/3 mt-4 md:mt-0 shadow-md"
                        style={formStyle}
                    >
                        {hasLoginError ? <div className="text-red-600 font-semibold text-lg">{loginErrorMessage}</div> : null}
                        <br />
                        <label
                            style={{
                                fontWeight: "bold",
                                fontSize: "25px",
                                color: "#180A39",
                                marginBottom: "2rem",
                            }}
                        >
                            Sign in
                        </label>
                        {/* <hr /> */}
                        <Form>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label style={{ color: "#180A39" }}>
                                    Email address:
                                </Form.Label>
                                <Form.Control
                                    type="email"
                                    required
                                    placeholder="Enter email"
                                    onChange={onChangeEmail}
                                    value={email}
                                />
                                <Form.Text
                                    className="text-muted"
                                    style={{ color: "white" }}
                                ></Form.Text>
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label style={{ color: "#180A39" }}>Password:</Form.Label>
                                <Form.Control
                                    type="password"
                                    required
                                    placeholder="Password"
                                    onChange={onChangePassword}
                                    value={password}
                                />
                            </Form.Group>
                            <Button
                                type="submit"
                                className="submit2 mb-2"
                                disabled={loading}
                                onClick={handleSubmit}
                            >
                                Login
                                {loading ? <i className="fa fa-spinner fa-spin ml-2"></i> : ""}
                            </Button>
                        </Form>
                    </div>

                    <br />
                </div>
            </div>
        </div>
    );
}
