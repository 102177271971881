

import katsina_state_logo2 from '../img/katsina_state_logo2.jpg';
import { Link } from "react-router-dom/cjs/react-router-dom";
export default function Footer({ setAboutUsModal, homePage }) {
    return (
        <footer class="bg-[rgb(4,91,98)] shadow dark:bg-gray-900">
            <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
                <div class="flex flex-row justify-center items-center ">
                    <ul class="flex flex-wrap items-center mb-6 text-sm font-medium text-white sm:mb-0 ">
                        <Link to="/login" style={{ textDecoration: 'none' }}>
                            <span class=" border-2 border-white rounded p-1 hover:text-gray-300 me-4 md:me-6 cursor-pointer">SDGs</span>
                        </Link>
                        {!homePage ?
                            <>
                                <li>
                                    <span class=" border-2 border-white rounded p-1 hover:text-gray-300 me-4 md:me-6 cursor-pointer" onClick={() => window.location.replace("/#about")}>About us</span>
                                </li>
                                <li>
                                    <span class=" border-2 border-white rounded p-1 hover:text-gray-300 me-4 md:me-6 cursor-pointer" onClick={() => window.location.replace("/#contact")}>Contact us</span>
                                </li>
                            </>
                            : null}
                    </ul>
                </div>
                <hr class="my-6 border-gray-200 sm:mx-auto lg:my-8" />
                <span class="block text-sm text-white sm:text-center">© {new Date().getFullYear()}</span>
            </div>
        </footer>
    )
}