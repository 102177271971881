
import location from "../img/location.png"
import email from "../img/email.png"
import phone from "../img/phone.png"
import { ClickAwayListener } from "@mui/material"
export default function ContactUs({ setContactUsModal }) {
    return (
        <ClickAwayListener onClickAway={() => setContactUsModal(false)}>
            <div class="position-absolute z-50 about-us p-4 w-full max-w-2xl max-h-full align-center">
                <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                            Katsina State Project Support Unit
                        </h3>
                        <button type="button" onClick={() => setContactUsModal(false)} class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span class="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="flex flex-row flex-wrap">
                        <div className="flex-none w-1/2 px-4">
                            <div className="font-bold text-lg">Contact Us</div>
                            <div className="py-3">
                                <div className="flex flex-col">
                                    <div className="flex flex-row">
                                        <img
                                            style={{ width: 30 }}
                                            src={location}
                                            alt="location"
                                        />
                                        <span>Location:</span>
                                    </div>
                                    <span>Olusegun Obasanjo Drive, VIP Opp. Water Board GRA, Katsina, Katsina State.</span>
                                </div>
                            </div>
                            <div className="py-3">
                                <div className="flex flex-col">
                                    <div className="flex flex-row">
                                        <img
                                            style={{ width: 30 }}
                                            src={email}
                                            alt="email"
                                        />
                                        <span>Email:</span>
                                    </div>
                                    <span>info@sdgskatsina.org</span>
                                </div>
                            </div>
                            <div className="py-3">
                                <div className="flex flex-col">
                                    <div className="flex flex-row">
                                        <img
                                            style={{ width: 30 }}
                                            src={phone}
                                            alt="phone"
                                        />
                                        <span>Call:</span>
                                    </div>
                                    <span>+234 814 957 8613</span>
                                </div>
                            </div>
                        </div>
                        <div className="flex-grow">
                            <iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Olusegun%20Obasanjo%20Drive,%20VIP%20Opp.%20Water%20Board%20GRA,%20Katsina,%20Katsina%20State+(Katsina%20State%20Government%20House)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                                <a href="https://www.gps.ie/">gps devices</a>
                            </iframe>
                        </div>
                    </div>
                    {/* <div class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                        <div>Image Here</div>
                    </div> */}
                </div>
            </div>
            {/* </div> */}
        </ClickAwayListener>
    )
}