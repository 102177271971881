import ssa_image from "../img/maryam.jpg"
import he_kastina from "../img/HE_kastina.jpg"
import { ClickAwayListener } from "@mui/material"
export default function ({ setAboutUsModal }) {
    return (
        <ClickAwayListener onClickAway={() => setAboutUsModal(false)}>
            {/* <div id="default-modal" tabindex="-1" aria-hidden="true" class="z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"> */}
            <div class="position-absolute z-50 about-us p-4 w-full max-w-2xl max-h-full align-center py-10">
                <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                            About Us
                        </h3>
                        <button type="button" onClick={() => setAboutUsModal(false)} class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span class="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div class="p-4 md:p-5 space-y-4">
                        <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                            Barrister Maryam Hameeda Sodangi is the current Senior Special Assistant to the Governor of Katsina State on Substainable Development Goals and Development Partners, serving concurrently as the Chief Executive Officer of the Katsina State Project Support Unit.
                            Additionally, she holds the position of UNDP Technical Assistant to the Katsina State Government
                        </p>
                        <div className="col-span-4 md:col-span-4">
                            <div className="flex flex-col md:flex-row justify-start items-center">
                                <div class="flex flex-col shadow h-[500px] w-[400px] rounded-lg justify-center items-center pr-4">
                                    <img
                                        // style={{ width: 400 }}
                                        src={he_kastina}
                                        alt="he_kastina"
                                        className="w-full h-5/6"
                                    />
                                    <span className="">
                                        <p className="text-lg font-sm">
                                            Mallam Dikko Umaru Radda, Ph.D
                                        </p>
                                        <p className="font-semibold">Executive Governor Kastina State</p>
                                    </span>
                                </div>
                                <div class="flex flex-col shadow h-[500px] w-[400px] rounded-lg justify-center items-center">
                                    <img
                                        // style={{ width: 400 }}
                                        src={ssa_image}
                                        alt="ssa_image"
                                        className="w-full h-5/6"
                                    />
                                    <span className="">
                                        <p className="text-lg font-sm">
                                            Barr. Maryam Hameeda Sodangi
                                        </p>
                                        <p className="font-semibold">SSA-SDGs & Development Partners</p>
                                    </span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ClickAwayListener>
    )
}