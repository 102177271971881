
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Container from '@mui/material/Container';
import HomePage from './components/HomePage';
import LandingPage from './components/LandingPage';
import LoginPage from './components/LoginPage'
import Visualization from './components/Visualization';
import AuthenticatedComponents from './components/authorization/AuthenticatedComponents';

function App() {
  return (
    <>
      {/* <Store> */}
      <Router>
        <Switch>
          <Route exact path='/'>
            <LandingPage />
          </Route>
          <Route path='/login'>
            <LoginPage />
          </Route>
          <AuthenticatedComponents>
            <Route exact path='/home'>
              <HomePage />
            </Route>
            <Route path='/visualization'>
              <Visualization />
            </Route>
          </AuthenticatedComponents>
        </Switch>
      </Router>
      {/* </Store> */}
    </>
  );
}

export default App;