// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .CircularProgressbar .CircularProgressbar-path {
  stroke-width: 12px;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke-width: 6px;
} */

.customCircularProgressbar  {
  background-color: "red"; 
}

/* .customCircularProgressbar .CircularProgressbar-trail {
  stroke-width: 12px; 
} */`, "",{"version":3,"sources":["webpack://./src/components/customCircularProgressBar.css"],"names":[],"mappings":"AAAA;;;;;;GAMG;;AAEH;EACE,uBAAuB;AACzB;;AAEA;;GAEG","sourcesContent":["/* .CircularProgressbar .CircularProgressbar-path {\n  stroke-width: 12px;\n}\n\n.CircularProgressbar .CircularProgressbar-trail {\n  stroke-width: 6px;\n} */\n\n.customCircularProgressbar  {\n  background-color: \"red\"; \n}\n\n/* .customCircularProgressbar .CircularProgressbar-trail {\n  stroke-width: 12px; \n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
