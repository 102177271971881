
export default function Table2() {
    return (
        <table className="table-fixed mb-2">
            <thead className=" border-black border-2">
                <tr className="bg-[#21B5A3]">
                    <th colSpan={13} className="px-2 text-center border-black border-l-2 border-r-2 border-b-2 border-b-2">Ratio of adjusted net attendance rates of girls to boys, in primary, junior and senior secondary school, and additional parity indices, Nigeria MICS, 2021</th>
                </tr>
                <tr className="border-black bg-[#21B5A3]">
                    <th></th>
                    <th className="px-2 border-black border-b-2 border-l-2 border-r-2 text-center" colSpan={4}>Primary school</th>
                    <th className="border-black border-b-2 border-l-2 border-r-2 px-2 text-center" colSpan={4}>Junior secondary schoolD</th>
                    <th className="border-black border-b-2 border-l-2 border-r-2 px-2 text-center" colSpan={4}>Senior secondary schoolE</th>
                </tr>
                <tr className="border-black border-b-2">
                    <td></td>
                    <td className="px-2 border-black border-l-2 border-r-2" rowSpan={2}>Primary school adjusted net attendance rate</td>
                    <td className="border-black border-l-2 border-r-2 px-2" rowSpan={2}>Primary school adjusted net attendance rate</td>
                    <td className="border-black border-l-2 border-r-2 px-2" rowSpan={2}>Primary school adjusted net attendance rate</td>
                    <td className="border-black border-l-2 border-r-2 px-2" rowSpan={2}>Gender parity index (GPI) for primary school</td>
                    <td className="border-black border-l-2 border-r-2 px-2" rowSpan={2}>Junior secondary school adjusted net attendance rate</td>
                    <td className="border-black border-l-2 border-r-2 px-2" rowSpan={2}>Junior secondary school adjusted net attendance rate</td>
                    <td className="border-black border-l-2 border-r-2 px-2" rowSpan={2}>Junior secondary school adjusted net attendance rate</td>
                    <td className="border-black border-l-2 border-r-2 px-2" rowSpan={2}>Gender parity index (GPI) for lower secondary school</td>
                    <td className="border-black border-l-2 border-r-2 px-2" rowSpan={2}>Senior secondary school adjusted net attendance rate</td>
                    <td className="border-black border-l-2 border-r-2 px-2" rowSpan={2}>Senior secondary school adjusted net attendance rate</td>
                    <td className="border-black border-l-2 border-r-2 px-2" rowSpan={2}>Senior secondary school adjusted net attendance rate</td>
                    <td className="border-black border-l-2 border-r-2 px-2" rowSpan={2}>Gender parity index (GPI) for upper secondary</td>
                </tr>
            </thead>
            <tbody className="border-black border-2">
                
                <tr className="border-black border-b-2">
                    <td></td>
                    <td className="px-2 border-black border-l-2 border-r-2 text-nowrap" >(ANAR), girls</td>
                    <td className="border-black border-l-2 border-r-2 px-2 text-nowrap" >(ANAR), boys</td>
                    <td className="border-black border-l-2 border-r-2 px-2 text-nowrap" >(ANAR), total <span className="superscript">1.2</span></td>
                    <td className="border-black border-l-2 border-r-2 px-2 text-nowrap" >ANAR <span className="superscript">3</span></td>
                    <td className="px-2 border-black border-l-2 border-r-2 text-nowrap" >(ANAR), girls</td>
                    <td className="border-black border-l-2 border-r-2 px-2 text-nowrap" >(ANAR), boys</td>
                    <td className="border-black border-l-2 border-r-2 px-2 text-nowrap" >(ANAR), total <span className="superscript">1.2</span></td>
                    <td className="border-black border-l-2 border-r-2 px-2 text-nowrap" >ANAR <span className="superscript">3</span></td>
                    <td className="px-2 border-black border-l-2 border-r-2 text-nowrap" >(ANAR), girls</td>
                    <td className="border-black border-l-2 border-r-2 px-2 text-nowrap" >(ANAR), boys</td>
                    <td className="border-black border-l-2 border-r-2 px-2 text-nowrap" >rate (ANAR) <span className="superscript">1.2</span></td>
                    <td className="border-black border-l-2 border-r-2 px-2 text-nowrap" >school ANAR <span className="superscript">3</span></td>
                </tr>
                <tr className="border-black border-b-2">
                    <td className="px-4 border-black border-l-2 border-r-2 font-bold text-center" >State</td>
                    <td className="border-black border-l-2 border-r-2" colSpan={12}></td>
                </tr>
                <tr className="border-black border-b-2">
                    <td className="px-4 border-black border-l-2 border-r-2 text-center" >Katsina</td>
                    <td className="px-2 border-black border-l-2 border-r-2" >63.8</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >64.1</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >64</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >1</td>
                    <td className="px-2 border-black border-l-2 border-r-2" >27.6</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >29.8</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >28.8</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >0.93</td>
                    <td className="px-2 border-black border-l-2 border-r-2" >34.2</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >32.3</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >33.3</td>
                    <td className="border-black border-l-2 border-r-2 px-2" >1.06</td>
                </tr>
                <tr className="border-black border-b-2 text-center font-bold text-sm">
                    <td colSpan={13}><p className="superscript font-normal">1</p>MICS indicator LN.11b - Parity indices - primary, lower and upper secondary attendance (wealth); SDG indicator 4.5.1</td>
                </tr>
                <tr className="border-black border-b-2 text-center font-bold text-sm">
                    <td colSpan={13}><p className="superscript font-normal">2</p>MICS indicator LN.11c - Parity indices - primary, lower and upper secondary attendance (area); SDG indicator 4.5.1</td>
                </tr>
                <tr className="border-black border-b-2 text-center font-bold text-sm">
                    <td colSpan={13}><p className="superscript font-normal">3</p>MICS indicator LN.11a - Parity indices - primary, lower and upper secondary attendance (gender); SDG indicator 4.5.1</td>
                </tr>
                <tr className="border-black border-b-2 text-center">
                    <td colSpan={13}><p className="superscript">A</p> The disaggregate of Mother's education is not available for children age 15-17 years identified as emancipated or those age 18 at the time of interview. The sum of cases in the disaggregate may not equal the total denominator.</td>
                </tr>
                <tr className="border-black border-b-2 text-center">
                    <td colSpan={13}><span>na:</span> not applicable</td>
                </tr>
                <tr className="border-black border-b-2 text-center">
                    <td colSpan={13}><p class="superscript">D</p> Junior secondary includes Junior secondary and Vocational/ Innovative Enterprise Programmes (VEI/IEI)</td>
                </tr>
                <tr className="border-black border-b-2 text-center">
                    <td colSpan={13}><span class="superscript">E</span> Senior secondary includes Senior secondary and Secondary Technical</td>
                </tr>
            </tbody>
        </table>
    )
}
