
export default function Table1() {
    return (
        <table className="table-fixed mb-2">
            <thead className="bg-[#21B5A3] border-black border-2">
                <tr className="">
                    <th></th>
                    <th colSpan={3} className="px-6 text-center border-black border-l-2 border-r-2 border-b-2 border-b-2">MPI</th>
                    <th colSpan={3} className="px-6 text-center border-black border-l-2 border-r-2 border-b-2">Incidence (H, %)</th>
                    <th colSpan={3} className="px-6 text-center border-black border-l-2 border-r-2 border-b-2">Intensity (A,%)</th>
                    <th rowSpan={2} colSpan={2} className="text-nowrap mx-5 border-black border-l-2 border-r-2 border-b-2 px-6 ">Population Share (%)</th>
                    <th rowSpan={2} colSpan={2} className=" px-6 border-black border-l-2 border-r-2 border-b-2">Number of Poor (Millions/thousand)</th>
                </tr>
                <tr className="border-black border-b-2">
                    <th></th>
                    <th className="px-6 border-black border-l-2 border-r-2">Value</th>
                    <th className="border-black border-l-2 border-r-2  px-6" colSpan={2}>Confidence interval (95%)</th>
                    <th className="border-black border-l-2 border-r-2  px-6">Value</th>
                    <th className="border-black border-l-2 border-r-2  px-6" colSpan={2}>Confidence interval (95%)</th>
                    <th className="border-black border-l-2 border-r-2  px-6">Value</th>
                    <th className="border-black border-l-2 border-r-2  px-6" colSpan={2}>Confidence interval (95%)</th>
                </tr>
            </thead>
            <tbody className="border-black border-2">
                <tr>
                    <td className="text-nowrap border-black border-r-2 border-b-2">Katsina</td>
                    <td className="px-6 border-black border-r-2 border-b-2">0.304</td>
                    <td className="px-6 border-black border-r-2 border-b-2">0.277</td>
                    <td className="px-6 border-black border-r-2 border-b-2">0.33</td>
                    <td className="px-6 border-black border-r-2 border-b-2">72.7</td>
                    <td className="px-6 border-black border-r-2 border-b-2">66.7</td>
                    <td className="px-6 border-black border-r-2 border-b-2">77.8</td>
                    <td className="px-6 border-black border-r-2 border-b-2">41.7</td>
                    <td className="px-6 border-black border-r-2 border-b-2">40.3</td>
                    <td className="px-6 border-black border-r-2 border-b-2">43.2</td>
                    <td colSpan={2} className="px-6 border-black border-r-2 border-b-2">4.5</td>
                    <td colSpan={2} className="px-6 border-black border-r-2 border-b-2">6.92 M</td>
                </tr>
                <tr>
                    <td className="text-nowrap pr-6 border-black border-r-2 border-b-2">Katsina Central</td>
                    <td className="px-6 border-black border-r-2 border-b-2">0.324</td>
                    <td className="px-6 border-black border-r-2 border-b-2">0.279</td>
                    <td className="px-6 border-black border-r-2 border-b-2">0.37</td>
                    <td className="px-6 border-black border-r-2 border-b-2">77.9</td>
                    <td className="px-6 border-black border-r-2 border-b-2">69.3</td>
                    <td className="px-6 border-black border-r-2 border-b-2">86.5</td>
                    <td className="px-6 border-black border-r-2 border-b-2">41.6</td>
                    <td className="px-6 border-black border-r-2 border-b-2">39.3</td>
                    <td className="px-6 border-black border-r-2 border-b-2">44</td>
                    <td colSpan={2} className="px-6 border-black border-r-2 border-b-2">1.7</td>
                    <td colSpan={2} className="px-6 border-black border-r-2 border-b-2">2,735</td>
                </tr>
                <tr>
                    <td className="text-nowrap pr-6 border-black border-r-2 border-b-2">Katsina North</td>
                    <td className="px-6 border-black border-r-2 border-b-2">0.334</td>
                    <td className="px-6 border-black border-r-2 border-b-2">0.287</td>
                    <td className="px-6 border-black border-r-2 border-b-2">0.382</td>
                    <td className="px-6 border-black border-r-2 border-b-2">76.1</td>
                    <td className="px-6 border-black border-r-2 border-b-2">68.1</td>
                    <td className="px-6 border-black border-r-2 border-b-2">84.1</td>
                    <td className="px-6 border-black border-r-2 border-b-2">43.9</td>
                    <td className="px-6 border-black border-r-2 border-b-2">41.4</td>
                    <td className="px-6 border-black border-r-2 border-b-2">46.5</td>
                    <td colSpan={2} className="px-6 border-black border-r-2 border-b-2">1.5</td>
                    <td colSpan={2} className="px-6 border-black border-r-2 border-b-2">2,388</td>
                </tr>
                <tr>
                    <td className="text-nowrap pr-6 border-black border-r-2 border-b-2">Katsina South</td>
                    <td className="px-6 border-black border-r-2 border-b-2">0.245</td>
                    <td className="px-6 border-black border-r-2 border-b-2">0.196</td>
                    <td className="px-6 border-black border-r-2 border-b-2">0.293</td>
                    <td className="px-6 border-black border-r-2 border-b-2">62.7</td>
                    <td className="px-6 border-black border-r-2 border-b-2">51.9</td>
                    <td className="px-6 border-black border-r-2 border-b-2">73.5</td>
                    <td className="px-6 border-black border-r-2 border-b-2">39</td>
                    <td className="px-6 border-black border-r-2 border-b-2">36.4</td>
                    <td className="px-6 border-black border-r-2 border-b-2">41.5</td>
                    <td colSpan={2} className="px-6 border-black border-r-2 border-b-2">1.4</td>
                    <td colSpan={2} className="px-6 border-black border-r-2 border-b-2">1,795</td>
                </tr>
            </tbody>
        </table>
    )
}
