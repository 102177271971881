import React from 'react';
import MyChartComponent from './MyChartComponent';
import { Line } from 'react-chartjs-2';



const MultipleLineChart = ({ arrayOfDataSetFunction, text, maintainAspectRatio, labels }) => {
  const data = {
    labels: labels,
    datasets: arrayOfDataSetFunction
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: true,
      text: text
    },
    plugins: {
      legend: {
        position: "bottom"
      },
      title: {
        display: true,
        text: text
      }
    },
    scales: {
      xAxes: [{
        type: 'category',
        scaleLabel: {
          display: true,
          labelString: 'Year'
        }
      }],
      y: {
        stacked: false,
        display: true,
        grid: {
          display: true // Hide grid lines if needed
        }
      }
    }
  };

  return (
    <div className="w-full h-96">
      <Line data={data} options={options} />
    </div>
    );
};

export default MultipleLineChart
